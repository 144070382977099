<template>
  <div class="addressList">
    <nut-addresslist
      :key="len"
      :data="data"
      @handelDelIcon="delClick"
      @handelEditIcon="editClick"
      @handelItem="itemClick"
      @addAddressClick="addAddressClick"
      @swipeDelClick="swipeDelClick"
      :show-bottom-button="true"
      :swipe-edition="true"
      :dataMapOptions="dataMapOptions"
    >
    </nut-addresslist>
  </div>
</template>
<script>
import { ref, reactive } from 'vue';
import { Toast } from "@nutui/nutui";
import * as addressApi from "../../api/address";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const data = ref([

    ]);
    const dataMapOptions = reactive({
      id: 'id',
    });
    const len = ref(0);
    const getAddressList = async () => {
      let res = await addressApi.list();
      if(res.code == 0){
        var list = [];
        var addressList = res.data.list;
        var addressListLength = addressList.length;
        for(var i=0; i< addressListLength; i++){
          var id = addressList[i]["address_id"];
          var addressName = addressList[i]["real_name"];
          var defaultAddress = addressList[i]["is_default"] == 1 ? true : false;
          var fullAddress = addressList[i]["address_one"] + "/" +addressList[i]["address_two"];
          var phone = addressList[i]["mobile"];
          list.push({
            id: id,
            addressName: addressName,
            phone: phone,
            defaultAddress: defaultAddress,
            fullAddress: fullAddress,
          });
        }
        data.value = list;
        len.value = list.length;
      }else{
        Toast.text(res.message);
      }
      console.log(data.value);
    };
    const delAddress = async (addressId) => {
      const res = await addressApi.remove({ address_id: addressId });
      if(res.code ==0){
        getAddressList();
      }else{
        Toast.text(res.message);
      }
    };
    const itemClick = () => {
      // Toast.text('点击了地址哦～');
    };
    const delClick = (_,addressInfo) => {
      delAddress(addressInfo.id);
    };
    const editClick = (_,addressInfo) => {
      router.push("/address/edit?address_id="+addressInfo.id);
    };
    const addAddressClick = () => {
      router.push("/address/add");
    };
    const swipeDelClick = (_,addressInfo) => {
      delAddress(addressInfo.id);
    };

    getAddressList();
    return {
      itemClick,
      delClick,
      editClick,
      addAddressClick,
      swipeDelClick,
      dataMapOptions,
      data,
      len,
    };
  }
};
</script>

<style>
.addressList{
  margin: 0.5rem;
}
</style>
